
































import { defineComponent, computed } from '@vue/composition-api';
import { Tooltip } from '@nimiq/vue-components';
import { useAddressStore } from '../../stores/Address';
import { useStakingStore } from '../../stores/Staking';

import HeroIcon from '../icons/Staking/HeroIcon.vue';

export default defineComponent({
    setup() {
        const { activeAddressInfo } = useAddressStore();
        // const { activeStake } = useStakingStore();
        // const visible = computed(() => !activeStake.value?.balance);
        const visible = computed(() => true);

        return {
            visible,
            activeAddressInfo,
        };
    },
    props: {
        asButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        inversePalette: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        Tooltip,
        HeroIcon,
    },
});
