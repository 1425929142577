
















































































import { defineComponent, computed } from '@vue/composition-api';
import {
    CircleSpinner,
    AlertTriangleIcon,
    FiatAmount,
    CrossIcon,
    Identicon,
} from '@nimiq/vue-components';
import { TransactionState } from '@nimiq/electrum-client';
import { SwapAsset } from '@nimiq/fastspot-api';
import { useFiatStore } from '../stores/Fiat';
import { Transaction } from '../stores/BtcTransactions';
import Avatar from './Avatar.vue';
import Amount from './Amount.vue';
import FiatConvertedAmount from './FiatConvertedAmount.vue';
// import HistoricValueIcon from './icons/HistoricValueIcon.vue';
import UsdcIcon from './icons/UsdcIcon.vue';
import BankIcon from './icons/BankIcon.vue';
import SwapSmallIcon from './icons/SwapSmallIcon.vue';
import { FIAT_PRICE_UNAVAILABLE, BANK_ADDRESS } from '../lib/Constants';
import { useBtcTransactionInfo } from '../composables/useBtcTransactionInfo';
import { useFormattedDate } from '../composables/useFormattedDate';
import TransactionListOasisPayoutStatus from './TransactionListOasisPayoutStatus.vue';

export default defineComponent({
    props: {
        transaction: {
            type: Object as () => Transaction,
            required: true,
        },
    },
    setup(props) {
        const constants = { FIAT_PRICE_UNAVAILABLE, BANK_ADDRESS };

        const state = computed(() => props.transaction.state);

        const transaction = computed(() => props.transaction);

        const {
            amountReceived,
            amountSent,
            data,
            isCancelledSwap,
            isIncoming,
            peerAddresses,
            peerLabel,
            outputsReceived,
            outputsSent,
            swapData,
            swapInfo,
            swapTransaction,
        } = useBtcTransactionInfo(transaction);

        const timestamp = computed(() => transaction.value.timestamp && transaction.value.timestamp * 1000);
        const { dateDay, dateMonth, dateTime } = useFormattedDate(timestamp);

        // Fiat currency
        const { currency: fiatCurrency } = useFiatStore();

        const fiatValue = computed(() => {
            const outputsToCount = isIncoming.value ? outputsReceived.value : outputsSent.value;
            let value = 0;
            for (const output of outputsToCount) {
                if (!output.fiatValue || output.fiatValue[fiatCurrency.value] === undefined) return undefined;
                if (output.fiatValue[fiatCurrency.value] === FIAT_PRICE_UNAVAILABLE) return FIAT_PRICE_UNAVAILABLE;
                value += output.fiatValue[fiatCurrency.value]!;
            }
            return value;
        });

        return {
            constants,
            state,
            TransactionState,
            dateDay,
            dateMonth,
            dateTime,
            data,
            amountReceived,
            amountSent,
            fiatCurrency,
            fiatValue,
            isIncoming,
            peerAddresses,
            peerLabel,
            SwapAsset,
            swapInfo,
            swapData,
            swapTransaction,
            isCancelledSwap,
        };
    },
    components: {
        CircleSpinner,
        CrossIcon,
        AlertTriangleIcon,
        Avatar,
        Amount,
        FiatConvertedAmount,
        FiatAmount,
        // HistoricValueIcon,
        Identicon,
        UsdcIcon,
        BankIcon,
        SwapSmallIcon,
        TransactionListOasisPayoutStatus,
    },
});
